
.hero
{
  background: rgb(31,75,153);
	background: linear-gradient(90deg, rgba(31,75,153,1) 0%, rgba(86,66,166,1) 100%);
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
	justify-content: center;
  align-content: center;
  align-items: center;
  padding: 3em;
}

.hero section
{
  width: 50%;
}

.hero section img
{
  width: 100%;
}

.hero h1
{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 3.8em;
  margin: 0;
  color: white;
  line-height: 1.2em;
}
.hero h1 strong
{
  font-weight: 300;
}

.hero p
{
  font-size: 1.38em;
  margin-top: 1em;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.6em;
  font-family: 'Raleway', sans-serif;
  color: white;
  /* text-shadow: black 0px 0px 10px; */
}

.hero .bp3-button
{
  color: white;
  /* text-shadow: black 0px 0px 10px; */
  border: 2px solid white;
  border-radius: 0;
  padding: 15px;
  width: 180px;
}

.hero .bp3-button:hover
{
  background-color: white;
  color: #137CBD;
  text-shadow: none;
}
