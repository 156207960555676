
footer
{
  border-top: 1px solid #CED9E0;
  background-color: #F5F8FA;
  /* margin-top: 5em; */
}

footer a,
footer a:hover,
footer li,
footer h5.bp3-heading
{
  color: #A7B6C2;
}

footer h5.logo
{
  color: black;
}

