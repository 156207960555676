

table.bp3-html-table 
{ 
   border-collapse: separate; 
   border-spacing: 0 2px;
   table-layout: fixed;
   width: 100%;
   white-space: nowrap;
}

table.bp3-html-table tbody tr:first-child td
{
   box-shadow: none;
}

table.bp3-html-table td
{
   border-top: 1px solid #eee;
   border-bottom: 1px solid #eee;
   margin: 10px 10px 10px 10px;
}

table.bp3-html-table td:first-child
{
   border-left: 1px solid #eee;
}

table.bp3-html-table td:last-child
{
   border-right: 1px solid #eee;
}

.bp3-icon-secondary
{
   color: #A7B6C2;
}

table.bp3-html-table .bp3-tr-collapse td
{
   padding: 0px;
   border: none;
}

table.bp3-html-table .bp3-tr-collapse td:hover
{
   background: none !important;
}
