
/* .sourcecode
{
  padding-left: 3em;
} */

.sourcecode h2,
.sourcecode .bp3-tab-list
{
  /* background-color: #EBF1F5; */
}

.sourcecode .bp3-tab-list
{
  border-bottom: 1px solid #E1E8ED;
}

.suites .bp3-tab-list
{
  background-color: white;
  border-bottom: none;
}
