
.subscription p
{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subscription h3.bp3-heading
{
  justify-content: center;
}

.subscription .bp3-button
{
  width: 100%;
  text-align: center;
}

.subscription .bp3-tag
{
  width: 100%;
  text-align: center;
}