@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';

@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


ul li
{
   margin: 0.7em 0 0.7em 0;
}

li .bp3-icon
{
   color: #A7B6C2;
}

.chrn-text-success
{
  color: #0D8050;
}

.chrn-text-failure
{
  color: #C23030;
}

.chrn-text-pending
{
  color: #BF7326;
}

*
{
  -webkit-font-smoothing: antialiased;
  font-family: 'Raleway', sans-serif;  
}

h2.bp3-heading,
h2.bp3-heading a,
h3.bp3-heading,
h4.bp3-heading,
h5.bp3-heading
{
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.bp3-tab
{
  font-family: 'Montserrat', sans-serif;
}


ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}

.bp3-text-secondary
{
  color: #A7B6C2;
}

.bp3-tab-indicator-wrapper .bp3-tab-indicator
{
  height: 2px;
}

*:focus
{
  outline: none;
}

.bp3-breadcrumb,
.bp3-breadcrumb-current
{
  font-size: 14px;
}

.bp3-spinner
{
  display: inline-block;
}

.bp3-editable-text
{
  width: 100%;
}

.bp3-progress-bar
{
  background-color: #DB3737;
}


.bp3-navbar.bp3-dark
{
  background-color:#182026;
}

pre
{
  font-weight: 500;
}