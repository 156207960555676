@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
ul li
{
   margin: 0.7em 0 0.7em 0;
}

li .bp3-icon
{
   color: #A7B6C2;
}

.chrn-text-success
{
  color: #0D8050;
}

.chrn-text-failure
{
  color: #C23030;
}

.chrn-text-pending
{
  color: #BF7326;
}

*
{
  -webkit-font-smoothing: antialiased;
  font-family: 'Raleway', sans-serif;  
}

h2.bp3-heading,
h2.bp3-heading a,
h3.bp3-heading,
h4.bp3-heading,
h5.bp3-heading
{
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.bp3-tab
{
  font-family: 'Montserrat', sans-serif;
}


ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}

.bp3-text-secondary
{
  color: #A7B6C2;
}

.bp3-tab-indicator-wrapper .bp3-tab-indicator
{
  height: 2px;
}

*:focus
{
  outline: none;
}

.bp3-breadcrumb,
.bp3-breadcrumb-current
{
  font-size: 14px;
}

.bp3-spinner
{
  display: inline-block;
}

.bp3-editable-text
{
  width: 100%;
}

.bp3-progress-bar
{
  background-color: #DB3737;
}


.bp3-navbar.bp3-dark
{
  background-color:#182026;
}

pre
{
  font-weight: 500;
}

.hero
{
  background: rgb(31,75,153);
	background: linear-gradient(90deg, rgba(31,75,153,1) 0%, rgba(86,66,166,1) 100%);
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
	justify-content: center;
  align-content: center;
  align-items: center;
  padding: 3em;
}

.hero section
{
  width: 50%;
}

.hero section img
{
  width: 100%;
}

.hero h1
{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 3.8em;
  margin: 0;
  color: white;
  line-height: 1.2em;
}
.hero h1 strong
{
  font-weight: 300;
}

.hero p
{
  font-size: 1.38em;
  margin-top: 1em;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.6em;
  font-family: 'Raleway', sans-serif;
  color: white;
  /* text-shadow: black 0px 0px 10px; */
}

.hero .bp3-button
{
  color: white;
  /* text-shadow: black 0px 0px 10px; */
  border: 2px solid white;
  border-radius: 0;
  padding: 15px;
  width: 180px;
}

.hero .bp3-button:hover
{
  background-color: white;
  color: #137CBD;
  text-shadow: none;
}


footer
{
  border-top: 1px solid #CED9E0;
  background-color: #F5F8FA;
  /* margin-top: 5em; */
}

footer a,
footer a:hover,
footer li,
footer h5.bp3-heading
{
  color: #A7B6C2;
}

footer h5.logo
{
  color: black;
}



/* .sourcecode
{
  padding-left: 3em;
} */

.sourcecode h2,
.sourcecode .bp3-tab-list
{
  /* background-color: #EBF1F5; */
}

.sourcecode .bp3-tab-list
{
  border-bottom: 1px solid #E1E8ED;
}

.suites .bp3-tab-list
{
  background-color: white;
  border-bottom: none;
}



table.bp3-html-table 
{ 
   border-collapse: separate; 
   border-spacing: 0 2px;
   table-layout: fixed;
   width: 100%;
   white-space: nowrap;
}

table.bp3-html-table tbody tr:first-child td
{
   box-shadow: none;
}

table.bp3-html-table td
{
   border-top: 1px solid #eee;
   border-bottom: 1px solid #eee;
   margin: 10px 10px 10px 10px;
}

table.bp3-html-table td:first-child
{
   border-left: 1px solid #eee;
}

table.bp3-html-table td:last-child
{
   border-right: 1px solid #eee;
}

.bp3-icon-secondary
{
   color: #A7B6C2;
}

table.bp3-html-table .bp3-tr-collapse td
{
   padding: 0px;
   border: none;
}

table.bp3-html-table .bp3-tr-collapse td:hover
{
   background: none !important;
}


table.bp3-html-table td
{
  vertical-align: middle;
}

table.bp3-html-table .bp3-control
{
  margin-bottom: 0px;
}

.subscription p
{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subscription h3.bp3-heading
{
  justify-content: center;
}

.subscription .bp3-button
{
  width: 100%;
  text-align: center;
}

.subscription .bp3-tag
{
  width: 100%;
  text-align: center;
}
.settings .bp3-tab-list
{
  border-bottom: 1px solid #E1E8ED;
}
